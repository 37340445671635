export const AudioConstant = {
  baby: require("./baby_land.mp3"),
  opportunity: require("./bigdeal_smalldeal.mp3"),
  bigDeal: require("./bigdeal_smalldeal.mp3"),
  smallDeal: require("./bigdeal_smalldeal.mp3"),
  charity: require("./charity_land.mp3"),
  roll: require("./dice_roll.mp3"),
  doodad: require("./doodad_land.mp3"),
  divorce: require("./divorce_land.mp3"),
  downsized: require("./downsized_land.mp3"),
  statementClose: require("./financial_statments_in.mp3"),
  statementOpen: require("./financial_statments_out.mp3"),
  audit: require("./lawsuit_land.mp3"),
  enterFT: require("./leave_rat_race_enter_fast_track.mp3"),
  market: require("./market_land.mp3"),
  nextPlayer: require("./next_player.mp3"),
  online: require("./online.mp3"),
  human: require("./open_to_human_v1.mp3"),
  pass: require("./pass_and_play.mp3"),
  payday: require("./payday_land.mp3"),
  pieceMovemonent: require("./piece_movement_v1.mp3"),
  begin: require("./press_to_begin.mp3"),
  taxAudit: require("./tax_audit_land.mp3"),
  cashflow: require("./cashflow_day_land_v1.mp3"),
};
